<script>
import { SuawMainContent, SuawInputGroup, SuawParagraph, SuawHeading, SuawBlockQuote } from "@suaw/suaw-component-library";
import ResourceFrame from "@/features/resources/components/ResourceFrame.vue";

export default {
  name: "OrganizerHandbook",
  components: {
    SuawMainContent,
    SuawInputGroup,
    SuawParagraph,
    SuawHeading,
    SuawBlockQuote,
    ResourceFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ResourceFrame>
      <SuawInputGroup group-style="default" direction="column" group-gap="quadruple">
        <SuawHeading level="2" content="Organizer Handbook" />
        <SuawParagraph
          text="Since 2007, our organizers have run thousands of events using our tried-and-true formats. Now it's your turn! If you'd like to become an organizer, follow our simple application process <a href='/resources/organizer'>here</a> to set up a video call with our team."
        />
        <SuawParagraph
          text="Before your call with our team, it's helpful to familiarize yourself with our events. <b>We ask that you attend at least one Shut Up & Write! event before applying to host your own.</b> Once your application has been approved, follow these steps to launch your own Shut Up & Write! event."
        />
        <SuawHeading level="3" content="Getting Started" />
        <SuawHeading level="4" content="1. Pick a format" />
        <SuawParagraph text="Decide whether you would like to host your events in person or online." />
        <SuawParagraph
          text="<b>In-person</b>: You’ll need to pick a venue for your in-person event. Great places to host include your local coffee shop, library, or bookstore. Choosing a venue requires preparation, but for some writers, nothing beats the connection and productivity gained from meeting face-to-face on a regular basis."
        />
        <SuawParagraph
          text="<b>Online:</b> Hosting an event online has a few advantages: it’s incredibly convenient, can be done from anywhere with a good internet connection, and allows writers to participate who may otherwise not be able to attend in-person events. Hosting an online event requires the use of a paid account on a video conferencing platform that allows for a meeting to run 60+ minutes."
        />
        <SuawHeading level="4" content="2. Pick a time" />
        <SuawParagraph
          text="Next, you’ll need to pick a time for your event. Most organizers run Shut Up & Write! sessions once a week, but some like to run events even more frequently. Choose a recurring date and time that works with your schedule and is also accessible for other writers, such as mid-morning on Saturdays, or weekdays after work."
        />
        <SuawHeading level="4" content="3. Pick an event duration" />
        <SuawParagraph
          text="How long are you going to write? A classic Shut Up & Write! is an hour of focused writing time, which is the duration we recommend for most organizers, especially those just starting out. An extended Shut Up & Write! is over an hour, but less than two hours. A Shut Up & Write! marathon is over two hours. If you’d like to write for a long period of time, remember to schedule breaks."
        />
        <SuawParagraph text="What defines a Shut Up & Write! event? No read-alouds, no critiques, no feedback. Just writing." />
        <SuawBlockQuote
          text="The “Classic” Shut Up & Write: Start with 15 minutes of introductions. Then, set a timer for the duration of your event, start the timer, and tell everyone to “Shut Up & Write!” Use about 15 minutes at the end of the event to check in and share how your writing went. Then, offer an opportunity for everyone to stay and socialize."
        />
        <SuawHeading level="4" content="4. Create your event" />
        <SuawParagraph
          text="Ready to get the word out about your event? To help our organizers reach as many writers as possible, we list all events on both <a href='https://www.meetup.com/pro/shut-up-write/' target='_blank'>Meetup</a> and our own Shut Up & Write! website. Contact questions@shutupwrite.com and we'll help you set everything up."
        />
        <SuawHeading level="4" content="5. Spread the word" />
        <SuawParagraph
          text="Now that you’ve created your event, other writers in our network will be able to find it! Additionally, we encourage you to promote your events on social media or leave flyers at your chosen venue."
        />
        <SuawHeading level="3" content="Additional Tips" />
        <SuawHeading level="4" content="Ask a Friend to Help" />
        <SuawParagraph
          text="Having a co-host is a great way to have ongoing support, and we encourage this whenever possible. Try asking on social media or at the end of your event to find potential co-hosts. A co-host can serve as an emergency backup, be as involved as the main organizer, or strike a balance in between. If you identify a co-host, find an arrangement that works best for everyone. (Yes, you can have multiple co-hosts!)"
        />
        <SuawHeading level="4" content="Know the Code" />
        <SuawParagraph
          text="Shut Up & Write! events are a space for accountable writing time. Be sure that you and your writers are following our <a href='/resources/code-of-conduct'>Code of Conduct</a> at all events."
        />
        <SuawHeading level="4" content="Keep Us Posted" />
        <SuawParagraph
          text="Regularly check in with the Shut Up & Write! Community Team about your events, ideas you have about how to support your group, and any <a href='https://shutupwrite.typeform.com/to/EI3Ohubg' target='_blank'>success stories</a> you’d like to share. Email us at questions@shutupwrite.com."
        />
        <SuawParagraph text="(P.S. Be sure to read the <a href='/resources/organizer-faq'>Organizer FAQ</a> as it covers a lot of helpful information you'll need to know as a host!)" />
        <SuawHeading level="3" content="In-Person Events" />
        <SuawHeading level="4" content="Format" />
        <SuawParagraph
          text="Standard in-person events last <b>90 minutes</b>. If you'd like to host for a longer period of time, this format can be expanded. An extended Shut Up & Write! is over an hour, but less than two hours. A Shut Up & Write! marathon is over two hours. All of our events share our reliable format of checking in before and after your writing time. Here's an example of a classic Shut Up & Write! event schedule:"
        />
        <SuawParagraph
          text="<ul>
            <li>15 mins: Introductions</li>
            <li>60 mins: Shut Up & Write!</li>
            <li>15 mins: Conclusion</li>
          </ul>"
        />
        <SuawHeading level="4" content="Setup" />
        <SuawParagraph
          text="Print a copy of our <a href='/resources/downloads'>tabletop flyer</a> and leave it in an easily visible place so your writers can find you at your chosen venue. You can include other tips (“I always reserve the table in back,” etc.) on your event listing page."
        />
        <SuawHeading level="4" content="Event Discussions" />
        <SuawParagraph
          text="Your event listing page includes a discussion area for you and your event attendees. As the host of the event, you are also the host of this discussion space. We hope you’ll use this space to get to know each other! "
        />
        <SuawHeading level="4" content="Tips" />
        <SuawParagraph
          text="<ul><li>Speak to the manager at the venue to learn whether or not you’ll need to make a reservation ahead of time.</li>
            <li>Encourage your attendees to patronize the venue in some way, whether that’s buying a beverage or a snack.</li>
            <li>If you need to send a last-minute message to your attendees, go to your event page and post a new message in the discussion board. Your members will receive a notification.</li></ul>"
        />
        <SuawHeading level="4" content="Tricky Situations" />
        <SuawParagraph
          text="<ul><li>Latecomers: Give them a friendly wave and let them know you’ll check in with them after the timer goes off that concludes the writing portion of your event.</li>
            <li>Overly talkative writers: If you’re in the writing portion of the event, remind them that it’s time for quiet writing, but there will be time for socializing at the end.</li>
              <li>Bossy writers: Hold your ground! If someone insists on changing your event’s venue or timing to suit their needs, let them know they can <a href='/resources/organizer'>start their own event</a>.</li></ul>"
        />
        <SuawHeading level="4" content="Inappropriate Behaviors" />
        <SuawParagraph
          text="This is thankfully a very rare occurrence within our community, but in case this does happen, please know that our team will be available to support you. Email hostsupport@shutupwrite.com and report as many details as possible, including dates, times, and what was said between the involved parties. All community members must abide by our <a href='/resources/code-of-conduct'>Code of Conduct</a>. In the event that you witness illegal behavior at one of your events, please contact your local authorities."
        />
        <SuawHeading level="3" content="Online Events" />
        <SuawHeading level="4" content="Format" />
        <SuawParagraph
          text="Standard online events last 90 minutes. If you'd like to host for a longer period of time, this format can be expanded. An extended Shut Up & Write! is over an hour, but less than two hours. A Shut Up & Write! marathon is over two hours. All of our events share our reliable format of checking in before and after your writing time. Here's an example of a classic online event schedule:"
        />
        <SuawParagraph
          text="<ul>
            <li>5 mins: Introductions</li>
            <li>60 mins: Shut Up & Write!</li>
            <li>25 mins (or less): Conclusion</li>
          </ul>"
        />
        <SuawHeading level="4" content="Setup" />
        <SuawParagraph
          text="In order to make sure your online event runs smoothly, we recommend the following steps. These instructions are Zoom-specific, since a large percentage of our hosts use Zoom. For tips on Zoom alternatives, please check the website for your video hosting software of choice."
        />
        <SuawHeading level="4" content="Zoom Installation & Settings" />
        <SuawParagraph
          text="Make sure you are able to run Zoom by checking the <a href='https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0060748' target='_blank'>system requirements</a>. You will also need a stable internet connection."
        />
        <SuawParagraph text="Before your first meeting, make sure you have enabled the following Zoom settings:" />
        <SuawParagraph
          text="<ul>
            <li>Enable the waiting room</li>
            <li>Disable screen sharing</li>
            <li>During the “Shut Up & Write!” phase of your event, default to a Mute setting for all of your attendees in order to maintain a quiet writing environment.</li>
            <li>Also, during the “Shut Up & Write!” phase, enable “Mute Upon Entry.”</li>
            <li>For more on Zoom security, please review <a href='https://www.zoom.com/en/blog/keep-uninvited-guests-out-of-your-zoom-meeting/' target='_blank'>Zoom’s guide to keeping unwanted guests out of meetings</a>.</li>
          </ul>"
        />
        <SuawHeading level="4" content="Backgrounds" />
        <SuawParagraph
          text="As an online organizer, try to make sure your background is free from distractions. No need to be formal, but remember that others are trying to be productive!"
        />
        <SuawHeading level="4" content="Tricky Situations" />
        <SuawHeading level="5" content="Zoombombing" />
        <SuawParagraph
          text="If you find yourself getting “Zoombombed,” i.e. you have unwanted participants who are disrupting your session, remove them by mousing over their name and clicking “Remove.” Let the other writers know you are handling the situation and keep in mind that you’re not alone—this is a situation that others who have hosted Zoom meetings have also faced. Quick action will allow you to preserve the time that remains for writing."
        />
        <SuawHeading level="5" content="Inappropriate Behavior" />
        <SuawParagraph
          text="This is thankfully a very rare occurrence within our community, but in case this does happen, please know that our team will be available to support you. Email hostsupport@shutupwrite.com and report as many details as possible, including dates, times, and what was said between the involved parties. All community members must abide by our <a href='/resources/code-of-conduct'>Code of Conduct</a>. In the event that you witness illegal behavior at one of your events, please contact your local authorities."
        />
      </SuawInputGroup>
    </ResourceFrame>
  </SuawMainContent>
</template>
